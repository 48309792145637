import { Link } from "react-router-dom";

import useTranslation from "../../hooks/useTranslation";

import TEXT from "../../config/text";
import PATHS from "../../config/paths";

import TitleComponent from "../../components/TitleComponent";

export default function BecomePartner() {
    const translation = useTranslation();

    const handleText = (text) => {
        if (translation?.pages?.register?.[text]) {
            return translation?.pages?.register?.[text];
        }

        return TEXT?.pages?.register?.[text];
    };

    return (
        <div>
            <TitleComponent style={{ textTransform: "uppercase" }}>
                {handleText("title")}
            </TitleComponent>

            <div style={{ textAlign: "center", marginBottom: 20 }}>
                <Link to={PATHS.INFO} target="_blank">
                    {handleText("subtitle")}
                </Link>
            </div>
        </div>
    );
}
