import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Form } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import useTranslation from '../../../hooks/useTranslation';

import ButtonComponent from '../../../components/ButtonComponent';

import TEXT from '../../../config/text';
import Wrapper from '../../../components/Wrapper';

import styles from "../index.module.scss";
import InputComponent from '../../../components/InputComponent';
import REQUESTS from '../../../api/requests';
import Message from '../../../components/Message';
import MultipleDeviceLoginEditor from '../../../editors/MultipleDeviceLoginEditor';

export default function EmailRegistration() {
    const [message, setMessage] = useState({
        type: false,
        text: ""
    })
    const [recaptchaValue, setRecaptchaValue] = useState(false);
    const [captchaSiteKey, setCaptchaSieKey] = useState(null);
    const translation = useTranslation();

    const { webSettings, isAdmin } = useSelector((state) => state.globalState);

    const [form] = Form.useForm();

    const pageStyle = {
        backgroundColor: webSettings?.pages?.multiple_device_login?.background_color,
        backgroundImage: `url(${webSettings?.pages?.multiple_device_login?.background_image})`,
        backgroundSize: "cover",
    };

    const handleText = (text) => {
        if (translation?.pages?.multiple_device_login?.[text]) {
            return translation?.pages?.multiple_device_login?.[text];
        }
        return TEXT?.pages?.multiple_device_login?.[text];
    };

    const getCaptchaSiteKey = () => {
        REQUESTS.CAPTCHA.GET_SITE_KEY(
            (data) => {
                setCaptchaSieKey(data);
            },
            (err) => { }
        );
    };

    const handleCaptchaChange = (value) => {
        REQUESTS.CAPTCHA.SENT_CAPTCHA_TOKEN(
            { token: value },
            (message) => {
                setRecaptchaValue(message.success);
            },
            (err) => setRecaptchaValue(false)
        );
    }

    const onFinish = (values) => {
        const { email } = values;

        const body = {
            email,
        };

        const callback = (response) => {
            setMessage({
                type: true,
                text: response
            })
        };

        const errorCallback = (error) => {
            if (typeof (error) == 'string') {
                setMessage({
                    type: false,
                    text: error
                })
            } else {
                setMessage({
                    type: false,
                    text: error.message
                })
            }
        };

        REQUESTS.EMAIL_REGISTRATION(body, callback, errorCallback);
    };

    const onSubmit = () => {
        form.submit();

        setMessage({
            type: false,
            text: ''
        });
    };

    const onValuesChange = () => {
        setMessage({
            type: false,
            text: ''
        });
    };

    useEffect(() => {
        getCaptchaSiteKey();
    }, [])

    return (
        <div style={pageStyle} className='page-wrapper'>
            {isAdmin && <MultipleDeviceLoginEditor />}
            <Wrapper>
                <h3 className={styles["login-multiple-device"]}>
                    {handleText("registration")}
                </h3>
                <Form
                    autoComplete="off"
                    name="login"
                    form={form}
                    onFinish={onFinish}
                    className={styles["form"]}
                    onValuesChange={onValuesChange}
                >
                    <div className={styles["form-content"]}>
                        <Form.Item
                            name='email'
                            rules={[
                                {
                                    type: 'email',
                                    message: handleText('email_validation_message'),
                                },
                                {
                                    required: true,
                                    message: handleText('email_message'),
                                },
                            ]}
                        >
                            <InputComponent placeholder={handleText("email_placeholder")} type="email" />
                        </Form.Item>
                    </div>

                    {
                                captchaSiteKey && <ReCAPTCHA
                                    sitekey={captchaSiteKey}
                                    onChange={handleCaptchaChange}
                                />
                    }
                    
                    <div style={{ marginBottom: 24 }}>
                        <Message message={message} />
                    </div>

                    <Form.Item shouldUpdate>

                        <ButtonComponent
                            title={"Send email"}
                            onClick={onSubmit}
                            disabled={captchaSiteKey && !recaptchaValue}
                        />
                    </Form.Item>

                </Form>
            </Wrapper>
        </div>
    )
}
