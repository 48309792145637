import { useState } from "react";
import { useSelector } from "react-redux";
import { Drawer, notification } from "antd";
import { SettingOutlined } from "@ant-design/icons";

import REQUESTS from "../../api/requests";

import style from "../_style.module.scss";

export default function EditorDrawer({ children, title, navbar, footer }) {
    const [open, setOpen] = useState(false);

    let { webSettings } = useSelector((state) => state.globalState);

    const save = () => {
        REQUESTS.UPDATE_EDITOR_CONFIGS(
            { json_configs: JSON.stringify(webSettings) },
            function () {
                notification.success({ description: "Success" });
            },
            function (err) {
                notification.error({ description: "Error" });
            }
        );
    };

    return (
        <>
            <div
                className={`${style["settings-button"]} ${navbar ? style["navbar"] : ""}  ${
                    footer ? style["footer"] : ""
                }`}
                onClick={() => setOpen(true)}
            >
                <SettingOutlined />
            </div>

            <Drawer
                title={title}
                anchor="right"
                open={open}
                onClose={() => setOpen(false)}
                maskStyle={{ background: "transparent" }}
            >
                <div>{children}</div>

                <div className={style["save-button"]} onClick={save}>
                    Save
                </div>
            </Drawer>
        </>
    );
}
