import { Input } from "antd";

export default function InputTextareaComponent({ title, optional, value, onChange, rows }) {
    return (
        <div style={{ marginBottom: 10 }}>
            {title && (
                <div style={{ marginBottom: 5 }}>
                    {title}{" "}
                    {optional && <span style={{ fontSize: 12, color: "grey" }}>(Optional)</span>}
                </div>
            )}

            <Input.TextArea
                value={value}
                rows={rows}
                onChange={(e) => onChange(e.target.value)}
                className={"textarea-component"}
            />
        </div>
    );
}
