import React, { useState, useEffect } from 'react'
import { Form, Input, Checkbox, Modal } from "antd";
import ReCAPTCHA from 'react-google-recaptcha';
import useTranslation from '../../../hooks/useTranslation';
import TEXT from '../../../config/text';
import REQUESTS from '../../../api/requests';
import InputComponent from '../../../components/InputComponent';
import ButtonComponent from '../../../components/ButtonComponent';
import ErrorModal from '../../../components/ErrorModal';
import style from "../styles/style.module.scss";

export default function EditPlaylist({
    open, onCancel,
    playlist,
    getPlaylists,
    protectedPlaylist,
    setProtectedPlaylist,
    pin
}) {
    const translation = useTranslation();

    const [isProtected, setIsProtected] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const [recaptchaValue, setRecaptchaValue] = useState(false);
    const [captchaSiteKey, setCaptchaSieKey] = useState(null);

    const [form] = Form.useForm();

    const handleText = (text) => {
        if (translation?.pages?.login_2?.[text]) {
            return translation?.pages?.login_2?.[text];
        }

        return TEXT?.pages?.login_2?.[text];
    };

    const getCaptchaSiteKey = () => {
        REQUESTS.CAPTCHA.GET_SITE_KEY(
            (data) => {
                setCaptchaSieKey(data);
            },
            (err) => { }
        );
    };

    const handleCaptchaChange = (value) => {
        REQUESTS.CAPTCHA.SENT_CAPTCHA_TOKEN(
            { token: value },
            (message) => {
                setRecaptchaValue(message.success);
            },
            (err) => setRecaptchaValue(false)
        );
    }

    const onFinish = (values) => {
        setErrorMessage(null);

        const body = {
            id: playlist.id,
            is_protected: isProtected,
            name: values.name,
            url: values.url,
            epg_url: values.epg_url || "",
        };

        if (isProtected && !protectedPlaylist) {
            body["new_pin"] = values.pin;
            body["confirm_pin"] = values.confirm_pin;
        }

        if (protectedPlaylist) {
            body["pin"] = pin.toString();

            if (isProtected) {
                delete body["is_protected"];
            }
        }

        function callback(data) {
            getPlaylists();
            setProtectedPlaylist(null)
            onCancel();
            setRecaptchaValue(false);
        }

        function errorCallback(error) {
            setErrorMessage(error.message);
        }

        REQUESTS.PLAYLIST.EDIT(body, callback, errorCallback);
    };

    useEffect(() => {
        if (open) {
            form.setFields([
                { name: "name", value: playlist.name },
                {
                    name: "url",
                    value: protectedPlaylist ? protectedPlaylist.url : playlist.url,
                },
                {
                    name: "epg_url",
                    value: protectedPlaylist ? protectedPlaylist.epg_url : playlist.epg_url,
                },
            ]);
            getCaptchaSiteKey()

            if (playlist["is_protected"]) {
                setIsProtected(true);
            }
        } else {
            setIsProtected(false);
            form.resetFields();
        }
    }, [open]);

    return (
        <div >
            <Modal
                closable={false}
                open={open}
                onCancel={onCancel}
                className="playlist-modal"
                footer={null}
            >
                <div className={style["edit-modal"]}>
                    <h1 className="playlist-modal-title">
                        {handleText("edit_playlist_modal_title")}
                    </h1>
                    <Form
                        form={form}
                        onFinish={onFinish}
                    >
                        <div style={{ width: "100%" }}>
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: handleText(""),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="url"
                                rules={[
                                    {
                                        required: true,
                                        message: handleText(""),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="epg_url"
                                rules={[
                                    {
                                        validator: (rule, value, callback) => {
                                            if (value && !/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(value)) {
                                                callback('Please enter a valid URL');
                                            } else {
                                                callback();
                                            }
                                        }
                                    },
                                ]}
                            >
                                <InputComponent placeholder={handleText("playlist_epg_url")} />
                            </Form.Item>
                            <Checkbox
                                checked={isProtected}
                                onChange={(e) => setIsProtected(e.target.checked)}
                                className={style["protected-playlist-checkbox"]}
                            >
                                {handleText("protect_playlist")}
                            </Checkbox>
                            <Form.Item
                                name="pin"
                                rules={[
                                    {
                                        required: protectedPlaylist ? false : isProtected,
                                        message: handleText('password_input_message'),
                                    },
                                ]}
                            >
                                <InputComponent
                                    type="password"
                                    disabled={protectedPlaylist ? true : !isProtected}
                                    placeholder={handleText("set_pin")}
                                />
                            </Form.Item>

                            <Form.Item
                                name="confirm_pin"
                                dependencies={["pin"]}
                                rules={[
                                    {
                                        required: protectedPlaylist ? false : isProtected,
                                        message: handleText('confirm_password_input_message'),
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("pin") === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    handleText('confirm_password_input_message_2')
                                                )
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <InputComponent
                                    type="password"
                                    disabled={protectedPlaylist ? true : !isProtected}
                                    placeholder={handleText("confirm_pin")}
                                />
                            </Form.Item>

                            {
                                captchaSiteKey && <ReCAPTCHA
                                    sitekey={captchaSiteKey}
                                    onChange={handleCaptchaChange}
                                />
                            }

                            <div style={{ marginTop: "40px", textAlign: "center" }}>
                                <ButtonComponent
                                    title={handleText("SAVE")}
                                    onClick={() => form.submit()}
                                    disabled={captchaSiteKey && !recaptchaValue}
                                />
                            </div>
                        </div>
                    </Form>
                </div>

            </Modal>
            <ErrorModal
                message={errorMessage}
                visible={errorMessage ? true : false}
                onClose={() => setErrorMessage(null)}
            />
        </div>
    )
}
