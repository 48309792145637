import { useState } from "react";

import { bindActionCreators } from "redux";

import { useDispatch, useSelector } from "react-redux";

import { actions } from "../store";

import EditorDrawer from "./components/EditorDrawer";
import ColorComponent from "./components/ColorComponent";
import ImageComponent from "./components/ImageComponent";

import BlockComponent from "./components/BlockComponent";

export default function RegisterEditor({}) {
    const dispatch = useDispatch();
    const { setWebSettings } = bindActionCreators(actions, dispatch);
    const { webSettings } = useSelector((store) => store.globalState);

    const changeBackgroundColor = (color) => {
        let configs = { ...webSettings };

        configs.pages.register.background_color = color;

        setWebSettings(configs);
    };

    const updateImage = (image) => {
        let configs = { ...webSettings };

        configs.pages.register.background_image = image;

        setWebSettings(configs);
    };

    return (
        <div>
            <EditorDrawer title={"Register settings"}>
                <ImageComponent
                    title={"Background image"}
                    defaultImage={webSettings?.pages?.register?.background_image}
                    updateImage={(e) => updateImage(e)}
                    deleteImage={() => updateImage("")}
                />

                <ColorComponent
                    defaultColor={webSettings?.pages?.register?.background_color}
                    text="Background color"
                    callback={changeBackgroundColor}
                />
            </EditorDrawer>
        </div>
    );
}
