import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import REQUESTS from '../../../api/requests';
import TEXT from '../../../config/text';
import useTranslation from '../../../hooks/useTranslation';

import styles from "../styles/style.module.scss";

export default function MyProfile() {
  const [deviceInfo, setDeviceInfo] = useState(null);

  const [paymentInfo, setPaymentInfo] = useState([]);

  const translation = useTranslation();

  const handleText = (text) => {
    if (translation?.pages?.login_2?.[text]) {
      return translation?.pages?.login_2?.[text];
    }
    return TEXT?.pages?.login_2?.[text];
  };

  const dateFormat = (date) => {
    const dateObj = new Date(date);
    let year = dateObj.getFullYear();
    let day = dateObj.getDate();
    let month = dateObj.getMonth() + 1;
    let minutes = dateObj.getMinutes();
    let hours = dateObj.getHours();

    year = year < 10 ? "0" + year : year;
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: "60px",
      render: (text, record, index) => index + 1,
    },
    {
      title: handleText("package_name"),
      dataIndex: 'package_name',
      key: 'package_name',
      width: "200px",
    },
    {
      title: handleText('payment_date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: "200px",
      render: (text, record) => dateFormat(text)
    },
    {
      title: handleText('package_type'),
      dataIndex: 'package_type',
      key: 'package_type',
      width: "200px",
    },
    {
      title: handleText('price'),
      dataIndex: 'amount',
      key: 'amount',
      width: "200px",
    },
    {
      title: handleText('transaction_id'),
      dataIndex: 'subscription_id',
      key: 'address',
      width: "200px",
    },
    {
      title: handleText('method'),
      dataIndex: 'method',
      key: 'method',
      width: "200px",
    },
    {
      title: handleText('status'),
      dataIndex: 'status',
      key: 'status',
      width: "200px",
    },
  ];

  const getDeviceInfo = () => {
    REQUESTS.DEVICE_INFO((data) => {
      setDeviceInfo(data)
    })
  };

  const getPaymentInfo = () => {
   
    REQUESTS.PAYMENT_INFO((data) => {
      setPaymentInfo(data)
    })
  }

  useEffect(() => {
    getPaymentInfo();
    getDeviceInfo();
  }, [])


  return (
    <div>
      <div className={styles['container-device-info']}>
        {deviceInfo && <div className={styles['device-info']}>
          <span><strong>{handleText('mac')} : </strong> {deviceInfo.mac}</span>
          <span><strong>{handleText('model')} : </strong> {deviceInfo.model}</span>
          <span><strong>{handleText('date')} : </strong> {
            deviceInfo.payed ? dateFormat(deviceInfo.activation_expired)
              :
              dateFormat(deviceInfo.free_trial_expired)
          }</span>
        </div>
        }
      </div>
      <h1>{handleText('profile_title')}</h1>
      <div>
        <Table
        rowKey="id"
          columns={columns}
          dataSource={paymentInfo}
          size='small'
          scroll={{ x: "max-content" ,  y: 340,}}
          pagination={false}
        />

      </div>
    </div>
  )
}
