import PropTypes from "prop-types";
import { Form } from "antd";

import BecomePartner from "./BecomePartner";

import InputComponent from "../../components/InputComponent";
import ButtonComponent from "../../components/ButtonComponent";
import useTranslation from "../../hooks/useTranslation";
import TEXT from "../../config/text";

/**
 * The company name, reseller name, last name, email address, and password are listed here as first part of the reseller registration process.
 *
 * @param {object} form         This is a state of our reseller form.
 * @param {func} setStep        In order to use this function, we must first pass a validation for go to the second part.
 *
 * @return First step
 */

export default function Step1({ form, setStep }) {
    const translation = useTranslation();

    const handleText = (text) => {
        if (translation?.pages?.register?.[text]) {
            return translation?.pages?.register?.[text];
        }

        return TEXT?.pages?.register?.[text];
    };

    const onClickNext = () => {
        form.validateFields(["company", "name", "surname", "email", "password"]).then(() => {
            setStep();
        });
    };

    return (
        <div>
            <BecomePartner />

            <Form.Item
                name="company"
                rules={[
                    {
                        required: true,
                        message: handleText("company_input_message"),
                    },
                ]}
            >
                <InputComponent placeholder={handleText("company_input_placeholder")} />
            </Form.Item>

            <Form.Item
                name="name"
                rules={[
                    {
                        required: true,
                        message: handleText("name_input_message"),
                    },
                ]}
            >
                <InputComponent placeholder={handleText("name_input_placeholder")} />
            </Form.Item>

            <Form.Item
                name="surname"
                rules={[
                    {
                        required: true,
                        message: handleText("surname_input_message"),
                    },
                ]}
            >
                <InputComponent placeholder={handleText("surname_input_placeholder")} />
            </Form.Item>

            <Form.Item
                name="email"
                rules={[
                    {
                        required: true,
                        type: "email",
                        message: handleText("email_input_message"),
                    },
                ]}
            >
                <InputComponent type="email" placeholder={handleText("email_input_placeholder")} />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: handleText("password_message"),
                    },

                    {
                        min: 8,
                        message: handleText("password_length_message"),
                    },
                ]}
            >
                <InputComponent
                    type="password"
                    placeholder={handleText("password_input_placeholder")}
                />
            </Form.Item>

            <div style={{ width: "max-content", margin: "auto", marginTop: 10 }}>
                <ButtonComponent
                    onClick={onClickNext}
                    title={handleText("step_one_button_title")}
                />
            </div>
        </div>
    );
}

Step1.propTypes = {
    form: PropTypes.object.isRequired,
    setStep: PropTypes.func.isRequired,
};
