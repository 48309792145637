import style from "./style/newsCard.module.scss";

import { formatDate } from "../utils";

export default function NewsCard({ news, cardClick }) {
    const { id, image, createdAt, title } = news;

    const clickCardHandler = () => {
        cardClick(id);
    };

    const setDefaultImage = (e) => {
        e.target.src = "assets/images/default.png";
    };

    return (
        <div className={style["news-card"]} onClick={clickCardHandler}>
            <figure>
                <img
                    src={image}
                    alt="news"
                    className={style["image"]}
                    // onError={setDefaultImage}
                />
                <figcaption className={style["added-on"]}>
                    Added on: <span>{formatDate(createdAt)}</span>
                </figcaption>
                <figcaption className={style["title"]}> {title} </figcaption>
            </figure>
        </div>
    );
}
