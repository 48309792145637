import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Form } from "antd";

import ReCAPTCHA from "react-google-recaptcha";

import REQUESTS from "../../api/requests";

import TEXT from "../../config/text";

import useTranslation from "../../hooks/useTranslation";

import ErrorModal from "../../components/ErrorModal";
import SuccessModal from "../../components/SuccessModal";
import InputComponent from "../../components/InputComponent";
import ButtonComponent from "../../components/ButtonComponent";
import ValidateMacAddress from "../../components/ValidateMacAddress";

import "./_style.scss";

export default function CouponActivation() {
  const translation = useTranslation();

  const [macIsValid, setMacIsValid] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [macValue, setMacValue] = useState("");

  const [searchParams] = useSearchParams();

  const [captchaSiteKey, setCaptchaSieKey] = useState(null);

  const [recaptchaValue, setRecaptchaValue] = useState(false);

  const [form] = Form.useForm();

  const handleText = (text) => {
    if (translation?.pages?.activation?.[text]) {
      return translation?.pages?.activation?.[text];
    }

    return TEXT?.pages?.activation?.[text];
  };

  const handleCaptchaChange = (value) => {
    REQUESTS.CAPTCHA.SENT_CAPTCHA_TOKEN(
      { token: value },
      (message) => {
        setRecaptchaValue(message.success);
      },
      (err) => setRecaptchaValue(false)
    );
  }

  const getCaptchaSiteKey = () => {
    REQUESTS.CAPTCHA.GET_SITE_KEY(
      (data) => {
        setCaptchaSieKey(data);
      },
      (err) => { }
    );
  };

  const onFinish = (values) => {
    function callback(data) {
      form.resetFields();
      setMacIsValid(false);
      setSuccessMessage(handleText("success_message"));
      setRecaptchaValue(false)
    }
    function errorCallback() {
      setSuccessMessage(null);
      setErrorMessage(handleText("error_message"));
    }
    REQUESTS.COUPON_ACTIVATION({ code: values.code, mac: macValue }, callback, errorCallback);
  };


  const onFinishFailed = () => { };

  useEffect(() => {
    getCaptchaSiteKey();

    if (searchParams.get("mac")) {
      setMacValue(searchParams.get("mac"))
    }
  }, [])

  return (
    <>
      <Form
        name="coupon-activation"
        form={form}
        className="activation-form-style"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div style={{ width: "100%", marginTop: 20 }}>
          <Form.Item
            name="code"
            required
            rules={[
              {
                required: true,
                message: handleText("gift_code_input_message"),
              },
            ]}
          >
            <InputComponent
              placeholder={handleText("gift_code_input_placeholder")}
            />
          </Form.Item>
          <ValidateMacAddress
            reset={successMessage}
            setMacIsValid={setMacIsValid}
            value={macValue}
            setValue={setMacValue}
          />
          {
            captchaSiteKey && <ReCAPTCHA
              sitekey={captchaSiteKey}
              onChange={handleCaptchaChange}
            />
          }
          <div className="coupon-activation-activate-btn">
            <ButtonComponent
              onClick={() => form.submit()}
              title={handleText("button_text")}
              disabled={captchaSiteKey ? (!recaptchaValue || !macIsValid) :!macIsValid}
            />
          </div>
        </div>
      </Form>
      <SuccessModal
        message={successMessage}
        visible={successMessage ? true : false}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorModal
        message={errorMessage}
        visible={errorMessage ? true : false}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
}
