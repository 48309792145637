import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useTranslation from '../../../hooks/useTranslation';
import REQUESTS from '../../../api/requests';
import TEXT from '../../../config/text';
import Playlist from './Playlist';
import AddPlaylist from '../../playlists/AddPlaylist';
import { ICONS } from '../../../config/icons';
import styles from "../styles/playlist.module.scss";

export default function MyPlaylists() {
  const [device, setDevice] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [visibleAddPlaylistModal, setVisibleAddPlaylistModal] = useState(false);

  const translation = useTranslation();

  const widgets = useSelector((state) => state.globalState.widgets);

  const handleText = (text) => {
    if (translation?.pages?.login_2?.[text]) {
      return translation?.pages?.login_2?.[text];
    }

    return TEXT?.pages?.login_2?.[text];
  };

  const getPlaylists = () => {
    REQUESTS.GET_PLAYLISTS((data) => {
      setDevice(data)
      setPlaylists(data.playlists)
    })
  };

  useEffect(() => {
    getPlaylists()
  }, [])

  return (
    <div>
      {
        playlists.map(playlist => <Playlist
          key={playlist.id}
          deviceId={device.id}
          playlist={playlist}
          getPlaylists={getPlaylists}
        />)
      }
      {
        widgets && widgets.xuione && widgets.xuione.enabled === "false" && <div
          className={styles["playlist-add-btn"]}
          onClick={() => setVisibleAddPlaylistModal(true)}
        >
          {ICONS.ADD}
          <span> {handleText("button_text")}</span>
        </div>
      }

      <AddPlaylist
        visible={visibleAddPlaylistModal}
        onCancel={() => setVisibleAddPlaylistModal(false)}
        getPlaylists={getPlaylists}
        deviceMac={device.mac}
      />
    </div>
  )
}
