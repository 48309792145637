import React, { useEffect, useState } from 'react';

import TEXT from '../../../config/text';

import useTranslation from '../../../hooks/useTranslation';

import REQUESTS from '../../../api/requests';

import styles from "../style/Applications.module.scss"

export default function Applications() {
  const [data, setData] = useState([]);

  const translation = useTranslation();

  const handleText = (text) => {
    if (translation?.pages?.home?.[text]) {
      return translation?.pages?.home?.[text];
    }

    return TEXT?.pages?.home?.[text];
  };

  const getApplications = () => {
    function callback(data) {
      setData(data.rows)
    }

    function errorCallback(data) { }

    REQUESTS.APPLICATIONS(callback, errorCallback);
  }

  useEffect(() => {
    getApplications();
  }, []);

  return (data.length > 0 &&
    <div className={styles['wrapper']}>
      <div className={styles['page-content']}>
        <h1 className={styles['page-title']}>{handleText("Download_for_free")}</h1>
        <div className={styles['content']}>
          {
            data.map(({ title, description, file }) => {
              return <div className={styles['single-application']}>
                <h2 className={styles['os']} >
                {title}
                </h2>
                <div dangerouslySetInnerHTML={{ __html: description}} className={styles['description']} />
                <a href={file} download={file} className={styles['download-btn']}>
                  {handleText("Download")}
                </a>
              </div>
            })
          }
        </div>
      </div>
    </div>
  )
}
