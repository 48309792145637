import React, { useEffect, useState } from 'react'
import { Form, Input } from "antd";
import ReCAPTCHA from 'react-google-recaptcha';
import TEXT from '../../config/text';
import REQUESTS from '../../api/requests';
import ErrorModal from '../../components/ErrorModal';
import SuccessModal from '../../components/SuccessModal';
import ButtonComponent from '../../components/ButtonComponent';
import useTranslation from '../../hooks/useTranslation';
import ValidateMacAddress from '../../components/ValidateMacAddress';
import "./__style.scss";

/**
 *  The modal is intended for Lg and Samsung QA teams, so that they can test the application.
 *  For activation, they must input the device mac address which is confirmed after validation, and voucher code which we provide to the LG and Samsung QA teams through documentation.
 *  After request is shown success or error modal.
 *
 * @param {bool} visible     For showing the modal
 * @param {func} onClose     For closing the modal
 *
 * @returns Modal
 */

export default function ActivationByVoucherCode() {
  const translation = useTranslation();
  const [loading, setLoading] = useState(false);

  const [macIsValid, setMacIsValid] = useState(false);
  const [macValue, setMacValue] = useState("");

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [recaptchaValue, setRecaptchaValue] = useState(false);
  const [captchaSiteKey, setCaptchaSiteKey] = useState(null);
  const [form] = Form.useForm();

  const handleText = (text) => {
    if (translation?.pages?.voucher_code?.[text]) {
      return translation?.pages?.voucher_code?.[text];
    }

    return TEXT?.pages?.voucher_code?.[text];
  };

  const getCaptchaSiteKey = () => {
    REQUESTS.CAPTCHA.GET_SITE_KEY(
      (data) => {
        setCaptchaSiteKey(data);
      },
      (err) => { }
    );
  };

  const handleCaptchaChange = (value) => {
    REQUESTS.CAPTCHA.SENT_CAPTCHA_TOKEN(
      { token: value },
      (message) => {
        setRecaptchaValue(message.success);
      },
      (err) => setRecaptchaValue(false)
    );
  }

  const onFinish = (values) => {
    setLoading(true);
    const body = {
      code: values.code,
      mac: macValue,
    };

    function callback() {
      setLoading(false);
      setRecaptchaValue(false)
      setSuccessMessage(handleText("qa_modal_success_message"));
    }

    function errorCallback(error) {
      setLoading(false);
      setErrorMessage(error.message);
    }

    REQUESTS.ACTIVATE_VOUCHER_CODE(body, callback, errorCallback);
  };

  useEffect(() => {
    getCaptchaSiteKey()
  }, [])
  return (
    <div className="page-wrapper">
      <div className="activate-device-modal">
        <h1 className="title">{handleText("qa_modal_title")}</h1>
        <Form
          form={form}
          layout="vertical"
          style={{ width: 300, marginTop: 40, }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <ValidateMacAddress
            value={macValue}
            setMacIsValid={setMacIsValid}
            setValue={setMacValue}
          />
          <Form.Item
            rules={[
              {
                required: true,
                message: handleText("qa_modal_voucher_code_input_message"),
              },
            ]}
            name="code"
          >
            <Input
              placeholder={handleText(
                "qa_modal_voucher_code_input_placeholder"
              )}
            />
          </Form.Item>

          {
            captchaSiteKey && <ReCAPTCHA
              sitekey={captchaSiteKey}
              onChange={handleCaptchaChange}
            />
          }

          <div style={{ marginTop: "40px", textAlign: "center" }}>
            <ButtonComponent
              type="primary"
              loading={loading}
              disabled={captchaSiteKey ? !recaptchaValue || !macIsValid : !macIsValid}
              onClick={() => form.submit()}
              title={handleText("qa_modal_button_title")}
            />
          </div>
        </Form>
      </div>
      <SuccessModal
        message={successMessage}
        visible={successMessage ? true : false}
        onClose={() => {
          setSuccessMessage(null);
        }}
      />
      <ErrorModal
        message={errorMessage}
        visible={errorMessage ? true : false}
        onClose={() => setErrorMessage(null)}
      />
    </div>
  )
}
