import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

import { actions } from "../store";

import ImageComponent from "./components/ImageComponent";

import ColorComponent from "./components/ColorComponent";

export default function SectionTwoEditor() {
    const dispatch = useDispatch();

    const { setWebSettings } = bindActionCreators(actions, dispatch);

    const { webSettings } = useSelector((store) => store.globalState);

    const setBgColor = (color) => {
        let configs = { ...webSettings };

        configs.pages.home.section_one.background_color = color;

        setWebSettings(configs);
    };

    const setBgImage = (image) => {
        let configs = { ...webSettings };

        configs.pages.home.section_one.background_image = image;

        setWebSettings(configs);
    };

    const setIcon = (image) => {
        let configs = { ...webSettings };

        configs.pages.home.section_one.icon = image;

        setWebSettings(configs);
    };

    const setIconSize = (size) => {
        let configs = { ...webSettings };

        configs.pages.home.section_one.icon_size = size;

        setWebSettings(configs);
    };

    const { background_color, background_image, icon, icon_size } =
        webSettings?.pages?.home?.section_one;

    return (
        <>
            <ImageComponent
                title={"Background image"}
                defaultImage={background_image}
                updateImage={(e) => setBgImage(e)}
                deleteImage={() => setBgImage("")}
            />

            <ColorComponent
                text="Background color"
                defaultColor={background_color}
                callback={setBgColor}
            />

            <ImageComponent
                size={icon_size}
                onChangeSize={setIconSize}
                title="Illustration"
                defaultImage={icon}
                updateImage={(e) => setIcon(e)}
                deleteImage={() => setIcon("")}
            />
        </>
    );
}
