import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { Form, notification,Checkbox } from "antd";

import ReCAPTCHA from "react-google-recaptcha";

import REQUESTS from "../../api/requests";

import TEXT from "../../config/text";
import ErrorModal from "../../components/ErrorModal";
import SuccessModal from "../../components/SuccessModal";
import InputComponent from "../../components/InputComponent";
import ButtonComponent from "../../components/ButtonComponent";
import ValidateMacAddress from "../../components/ValidateMacAddress";

/**
 *  Upload playlist by file: Entering in the user's device mac address, playlist name and choose the m3u8 file from the PC.
 *  After request is shown success or error modal.
 */

export default function UploadByFile ({captchaSiteKey }) {
  const translation = useSelector((store) => store.globalState.translation);

  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const [isProtected, setIsProtected] = useState(false);

  const [file, setFile] = useState(null);

  const [macIsValid, setMacIsValid] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const [successMessage, setSuccessMessage] = useState(null);

  const [macValue, setMacValue] = useState("");

  const [playlistName, setPlaylistName] = useState("");

  const [recaptchaValue, setRecaptchaValue] = useState(false);

  const [form] = Form.useForm();

  const handleText = (text) => {
    if (translation?.pages?.upload_playlist?.[text]) {
      return translation?.pages?.upload_playlist?.[text];
    }

    return TEXT?.pages?.upload_playlist?.[text];
  };

  const onFinish = (values) => {
    if (!file) {
      notification.error({
        description: "Please select m3u8 file",
      });

      return;
    }

    setLoading(true);

    const formData = new FormData();
    
    formData.append("name", values.name);
    formData.append("mac", macValue);

    if (file) {
      formData.append("image", file);
    }

    formData.append("is_protected", isProtected);

    if (isProtected) {
      formData.append("pin", values.pin);
      formData.append("confirm_pin", values.confirm_pin);
    }

    if (values.epg_url) {
      formData.append("epg_url", values.epg_url);
    }
    
    function callback(data) {
      setRecaptchaValue(false);
      setLoading(false);
      setFile(null);
      form.resetFields();
      setIsProtected(false);
      setMacValue("")

      setSuccessMessage("Playlist successfully added");
    }
    function errorCallback (error) {
      setLoading(false);

      setErrorMessage(error.message);
    }

    REQUESTS.UPLOAD_PLAYLIST(formData, callback, errorCallback);
  };

  const handleCaptchaChange = (value) => {
    REQUESTS.CAPTCHA.SENT_CAPTCHA_TOKEN(
      { token: value },
      (message) => {
        setRecaptchaValue(message.success);
      },
      (err) => setRecaptchaValue(false)
    );
  }

  const onFinishFailed = (error) => {
    setLoading(false);
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.name ) {
      setPlaylistName(changedValues.name)
    }
  };

  useEffect(()=>{
    if (playlistName && playlistName.length > 30) {
      setErrorMessage(handleText("error_message"));
    }
},[playlistName])

  useEffect(() => {
    if (searchParams.get("mac")) {
      setMacValue(searchParams.get("mac"))
    }
  }, [searchParams.get("mac")]);

  return (
    <>
      <Form
        autoComplete="off"
        name="upload-playlist-by-file"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
      >
        <ValidateMacAddress
          setMacIsValid={setMacIsValid}
          reset={successMessage}
          value={macValue}
          setValue={setMacValue}
        />

        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please input playlist name!",
            },
          ]}
        >
          <InputComponent placeholder="Playlist name" />
        </Form.Item>

        <Form.Item
          name="file"
          rules={[
            {
              required: true,
              message: "Please select m3u8 file!",
            },
          ]}
        >
          <InputComponent
            type="file"
            value={file}
            name={file?.name}
            placeholder={"Please select file"}
            accept=".m3u8, .m3u"
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />
        </Form.Item>
        <Form.Item
          name="epg_url"
          rules={[
            {
              validator: (rule, value, callback) => {
                if (value && !/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(value)) {
                  callback('Please enter a valid URL');
                } else {
                  callback();
                }
              }
            },
          ]}
        >
          <InputComponent placeholder={handleText("playlist_epg_url")} />
        </Form.Item>
        <Checkbox
          checked={isProtected}
          onChange={(e) => setIsProtected(e.target.checked)}
          className="protected"
          >
          {handleText("protect_playlist")}
        </Checkbox>

          <Form.Item
            name="pin"
            rules={[
              {
                required: isProtected ? true : false,
                message: handleText('password_message'),
              },
            ]}
            hasFeedback
          >
            <InputComponent
              type="password"
              placeholder={handleText("set_pin")}
              disabled={!isProtected}
            />
          </Form.Item>

          <Form.Item
            name="confirm_pin"
            dependencies={["pin"]}
            hasFeedback
            rules={[
              {
                required: isProtected ? true : false,
                message: handleText('confirm_password_input_message'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("pin") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      handleText('confirm_password_input_message_2')
                    )
                  );
                },
              }),
            ]}
          >
            <InputComponent
              type="password"
              placeholder={handleText("confirm_pin")}
              disabled={!isProtected}
            />
        </Form.Item>
        
        {
          captchaSiteKey && <ReCAPTCHA
            sitekey={captchaSiteKey}
            onChange={handleCaptchaChange}
          />
        }

        <div style={{ marginTop: "40px", textAlign: "center" }}>
          <ButtonComponent
            loading={loading}
            disabled={captchaSiteKey ? (!macIsValid || !recaptchaValue) : !macIsValid}
            onClick={() => form.submit()}
            title={"Upload"}
          />
        </div>
      </Form>

      <SuccessModal
        message={successMessage}
        visible={successMessage ? true : false}
        onClose={() => setSuccessMessage(null)}
      />

      <ErrorModal
        message={errorMessage}
        visible={errorMessage ? true : false}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
}
