import React, { useEffect, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { Form } from 'antd';

import ReCAPTCHA from "react-google-recaptcha";

import TEXT from '../../config/text';

import PATHS from "../../config/paths";

import useTranslation from '../../hooks/useTranslation';

import ButtonComponent from '../../components/ButtonComponent';
import Wrapper from '../../components/Wrapper';
import InputComponent from '../../components/InputComponent';

import styles from './index.module.scss';
import REQUESTS from '../../api/requests';
import Message from '../../components/Message';
import MultipleDeviceLoginEditor from '../../editors/MultipleDeviceLoginEditor';

export default function MultipleDeviceLogin() {
    const [message, setMessage] = useState({
        type: false,
        text: ""
    });

    const translation = useTranslation();

    const { webSettings, isAdmin } = useSelector((state) => state.globalState);

    const [captchaSiteKey, setCaptchaSieKey] = useState(null);

    const [recaptchaValue, setRecaptchaValue] = useState(false);

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const pageStyle = {
        backgroundColor: webSettings?.pages?.multiple_device_login?.background_color,
        backgroundImage: `url(${webSettings?.pages?.multiple_device_login?.background_image})`,
        backgroundSize: "cover",
    };

    const handleText = (text) => {
        if (translation?.pages?.multiple_device_login?.[text]) {
            return translation?.pages?.multiple_device_login?.[text];
        }
        return TEXT?.pages?.multiple_device_login?.[text];
    };

    const onFinish = (values) => {
        const { email, password } = values;

        const body = {
            email,
            password,
        };

        function callback(res) {
            localStorage.setItem('JWT', res);

            navigate(PATHS.DEVICES);
        };

        function errorCallback(error) {
            if (typeof (error) == 'string') {
                setMessage({
                    type: false,
                    text: error
                })
            } else {
                setMessage({
                    type: false,
                    text: error.message
                })
            }
        };
        REQUESTS.LOGIN_MULTIPLE_DEVICES_PAGE(body, callback, errorCallback)
    }

    const handleCaptchaChange = (value) => {
        REQUESTS.CAPTCHA.SENT_CAPTCHA_TOKEN(
            { token: value },
            (message) => {
                setRecaptchaValue(message.success);
            },
            (err) => setRecaptchaValue(false)
        );
    }

    const getCaptchaSiteKey = () => {
        REQUESTS.CAPTCHA.GET_SITE_KEY(
            (data) => {
                setCaptchaSieKey(data);
            },
            (err) => { }
        );
    };

    const onValuesChange = () => {
        setMessage({
            type: false,
            text: ''
        });
    };

    const onSubmit = () => {
        form.submit()
    }

    useEffect(() => {
        getCaptchaSiteKey();
    }, [])

    return (
        <div className='page-wrapper' style={pageStyle}>
            {isAdmin && <MultipleDeviceLoginEditor />}
            <Wrapper>
                <h3 className={styles["login-multiple-device"]}>
                    {handleText("title")}
                </h3>
                <Form
                    autoComplete="off"
                    name="login"
                    form={form}
                    onFinish={onFinish}
                    layout='vertical'
                    className={styles["form"]}
                    onValuesChange={onValuesChange}
                >
                    <div className={styles["form-content"]}>
                        <Form.Item
                            name='email'
                            rules={[
                                {
                                    type: 'email',
                                    message: handleText('email_validation_message'),
                                },
                                {
                                    required: true,
                                    message: handleText('email_message'),
                                },
                            ]}
                        >
                            <InputComponent
                                placeholder={handleText("email_placeholder")} />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    type: 'password',
                                    message: handleText('password_message'),
                                },
                                {
                                    required: true,
                                    message: handleText('password_message'),
                                },
                            ]}
                        >
                            <InputComponent type="password"
                                placeholder={handleText("password_placeholder")} />
                        </Form.Item>
                    </div>

                    <div className={styles["link"]}>
                        <Link to={PATHS.EMAIL_REGISTRATION}>{handleText('register_link')}</Link>
                    </div>

                    {
                        captchaSiteKey && <ReCAPTCHA
                            sitekey={captchaSiteKey}
                            onChange={handleCaptchaChange}
                        />
                    }

                    <div style={{ marginBottom: 24 }}>
                        <Message message={message} />
                    </div>

                    <Form.Item>
                        <ButtonComponent
                            title={handleText('button_text')}
                            onClick={onSubmit}
                            disabled={captchaSiteKey && !recaptchaValue}
                        />
                    </Form.Item>
                </Form>
            </Wrapper>
        </div>
    )
}
