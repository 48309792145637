import { Select, notification } from "antd";

import React, { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import REQUESTS from "../../../api/requests";

import PlaylistDataTable from "./PlaylistDataTable";

import defaultValues from "../logic/defaultValues";

import ButtonComponent from "../../../components/ButtonComponent";

import styles from "../styles/style.module.scss";

export default function ManageCategories() {
    const [searchParams] = useSearchParams();

    const [categorieType, setCatgorieType] = useState("tv");

    const [data, setData] = useState([]);

    const [values, setValues] = useState(null);

    const [selectedCategory, setSelectedCategory] = useState(null);

    const [isData, setIsData] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const onChangeData = (record, dataType, type) => {
        const newData = [...data];

        for (let i = 0; i < newData.length; i++) {
            if (newData[i].category_id === record.category_id) {
                if (dataType === "category") {
                    newData[i][type] = !newData[i][type];
                }

                for (let j = 0; j < newData[i].data.length; j++) {
                    if (dataType === "category") {
                        newData[i].data[j][type] = newData[i][type];
                    } else {
                        if (newData[i].data[j][returnRecordId()] === record[returnRecordId()]) {
                            newData[i].data[j][type] = !newData[i].data[j][type];
                        }
                    }
                }
            }
        }

        setData(newData);
    };

    // This function is for finding the array of values, wehere we will push the id of the category or stream. Function returned the array name.
    const returnArrayOfValue = (dataType, type) => {
        let currentArray = null;

        switch (categorieType) {
            case "tv":
                if (dataType === "category") {
                    if (type === "locked") {
                        currentArray = "locked_channel_categories";
                    } else {
                        currentArray = "hidden_channel_categories";
                    }
                } else {
                    if (type === "locked") {
                        currentArray = "locked_channels";
                    } else {
                        currentArray = "hidden_channels";
                    }
                }
                break;

            case "movie":
                if (dataType === "category") {
                    if (type === "locked") {
                        currentArray = "locked_movie_categories";
                    } else {
                        currentArray = "hidden_movie_categories";
                    }
                } else {
                    if (type === "locked") {
                        currentArray = "locked_movies";
                    } else {
                        currentArray = "hidden_movies";
                    }
                }
                break;

            case "serie":
                if (dataType === "category") {
                    if (type === "locked") {
                        currentArray = "locked_series_categories";
                    } else {
                        currentArray = "hidden_series_categories";
                    }
                } else {
                    if (type === "locked") {
                        currentArray = "locked_series";
                    } else {
                        currentArray = "hidden_series";
                    }
                }
                break;

            default:
                break;
        }

        return currentArray;
    };

    // This function is for returning the default value of the category or stream. Function returned the boolean value.
    // In this function we use the function returnArrayOfValue, which returned the array name.
    const returnDefaultValue = (dataType, type, id, isTrue) => {
        let currentArray = returnArrayOfValue(dataType, type);

        if (currentArray) {
            const finded = values[currentArray].find((item) => item === id);

            if (isTrue) {
                return true;
            }

            return finded ? true : false;
        }
    };

    // This function is for changing the values of the category or stream
    const onChangeValues = (record, dataType, type) => {
        const recordId = dataType === "category" ? record.category_id : record[returnRecordId()];

        const newValues = { ...values };

        // currentArray is the array name, which we get from the function returnArrayOfValue. We use this array name for pushing the id of the category or stream.
        let currentArray = returnArrayOfValue(dataType, type);

        if (currentArray) {
            // If the id is in the array, we remove the id from the array. If the id is not in the array, we push the id in the array.
            if (newValues[currentArray].indexOf(recordId) == -1) {
                newValues[currentArray] = [...newValues[currentArray], recordId];
            } else {
                const index = newValues[currentArray].indexOf(recordId);

                if (index !== -1) {
                    newValues[currentArray].splice(index, 1);
                }
            }

            setValues(newValues);
        }
    };

    const onClick = (record, dataType, type) => {
        // Data is the data of the category or stream. We use this data for changing the values of the category or stream.
        onChangeData(record, dataType, type);

        // This function is for changing the values of the category or stream.
        onChangeValues(record, dataType, type);
    };

    const getPlaylistData = () => {
        setSelectedCategory(null);

        setIsLoading(true);

        const id = searchParams.get("id");

        const query = {
            id,
            type: categorieType,
        };

        REQUESTS.PLAYLIST_DATA.GET(
            query,
            (data) => {
                const newData = data.groups.map((item, index) => {
                    const isLockedCategory = returnDefaultValue(
                        "category",
                        "locked",
                        item.category_id
                    );

                    const isHideCategory = returnDefaultValue("category", "hide", item.category_id);

                    return {
                        ...item,
                        locked: isLockedCategory,
                        hide: isHideCategory,
                        // position: returnPosition(item.category_id, index),
                        data: data.data
                            .filter((i) => i.category_id === item.category_id)
                            .map((item) => {
                                const id = item[returnRecordId()];

                                return {
                                    ...item,
                                    locked: returnDefaultValue(
                                        "data",
                                        "locked",
                                        id,
                                        isLockedCategory
                                    ),
                                    hide: returnDefaultValue("data", "hide", id, isHideCategory),
                                    // position: returnPosition(item.category_id, index), ///????
                                };
                            }),
                    };
                });

                setSelectedCategory(newData[0]);

                setData(newData);

                setIsLoading(false);
            },
            (err) => {
                setIsLoading(false);

                notification.error({
                    message: "Error",
                    description: "Something went wrong",
                });
            }
        );
    };

    const getPlaylists = () => {
        REQUESTS.GET_PLAYLISTS((data) => {
            const playlist = data.playlists.find((item) => item.id == searchParams.get("id"));

            if (playlist.categories) {
                const playlistCategories = JSON.parse(playlist.categories);

                setValues(playlistCategories);
            } else {
                setValues(defaultValues);
            }

            setIsData(true);
        });
    };

    const save = () => {
        const body = {
            id: +searchParams.get("id"),
            categories: JSON.stringify(values),
        };

        REQUESTS.PLAYLIST_DATA.EDIT(
            body,
            (data) => {
                notification.success({
                    message: "Success",
                    description: "Playlist was successfully saved",
                });
            },
            (err) => {}
        );
    };

    const returnRecordId = () => {
        return categorieType === "serie" ? "series_id" : "stream_id";
    };

    useEffect(() => {
        getPlaylists();
    }, []);

    useEffect(() => {
        if (isData) {
            getPlaylistData();
        }
    }, [categorieType, isData]);

    return (
        <div>
            <div className={styles["categories-types"]}>
                <Select
                    defaultValue={categorieType}
                    style={{
                        width: 120,
                    }}
                    onChange={setCatgorieType}
                    options={[
                        {
                            value: "tv",
                            label: "Live TV",
                        },
                        {
                            value: "movie",
                            label: "VOD",
                        },
                        {
                            value: "serie",
                            label: "TV Shows",
                        },
                    ]}
                />
                <div className={styles["save-btn"]}>
                    <ButtonComponent onClick={save} title={"Save"} />
                </div>
            </div>

            <div className={styles["categories-container"]}>
                <div>
                    <PlaylistDataTable
                        isLoading={isLoading}
                        data={data}
                        type="category"
                        keyValue="category_id"
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        onLock={(record) => onClick(record, "category", "locked")}
                        onHide={(record) => onClick(record, "category", "hide")}
                    />
                </div>
                <div>
                    <PlaylistDataTable
                        isLoading={isLoading}
                        data={selectedCategory?.data || []}
                        selectedCategory={selectedCategory}
                        type="data"
                        keyValue={returnRecordId()}
                        values={values}
                        onLock={(record) => onClick(record, "data", "locked")}
                        onHide={(record) => onClick(record, "data", "hide")}
                    />
                </div>
            </div>
        </div>
    );
}
