import PropTypes from "prop-types";

import TEXT from "../config/text";

import IMAGES from "../config/images";

import useTranslation from "../hooks/useTranslation";

import ModalComponent from "./ModalComponent";
import ButtonComponent from "./ButtonComponent";

import "../styles/error-modal.scss";

export default function ErrorModal({ visible, onClose, message }) {
    const translation = useTranslation();

    const handleText = (text) => {
        if (translation?.general?.[text]) {
            return translation?.general?.[text];
        }

        return TEXT?.general?.[text];
    };

    return (
        <ModalComponent open={visible} onCancel={onClose}>
            <div className="error-modal">
                <img src={IMAGES.ERROR} />
                <p>{message}</p>
                <ButtonComponent type="danger" onClick={onClose} title={handleText("close")} />
            </div>
        </ModalComponent>
    );
}

ErrorModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
