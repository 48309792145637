import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';

import { Button, Layout, Modal } from 'antd';
import TEXT from '../../../config/text';
import useTranslation from '../../../hooks/useTranslation';
import classNames from 'classnames';
import styles from "../styles/style.module.scss";


const { Header, Content } = Layout;

export default function ManagePlaylistLayout() {
    const navigate = useNavigate();
    const translation = useTranslation();
    const { webSettings, widgets } = useSelector((store) => store.globalState);
    const [modal, contextHolder] = Modal.useModal();

    const handleText = (text) => {
        if (translation?.pages?.login_2?.[text]) {
            return translation?.pages?.login_2?.[text];
        }
        return TEXT?.pages?.login_2?.[text];
    };

    const logout = () => {
        const TOKEN = localStorage.getItem("TOKEN");

        modal.confirm({
            title: null,
            icon: null,
            content: "Do you want to logout ?",
            okText: 'LOG OUT',
            cancelText: 'CANCEL',
            onOk: () => {
                if (TOKEN) {
                    localStorage.removeItem("TOKEN");

                }

                if (widgets?.multiple_device_register?.enabled === "true") {
                    navigate("/multiple-device-login")
                } else {
                    navigate('/login');
                }
            },
            className: 'ant-logout-confirm',
        });
    };

    return (
        <Layout>
            <Layout>
                <Header className={styles['header']}>
                    <div className={styles['header_menu']} >
                        <img
                            src={webSettings?.globals.logo}
                            alt="brand-logo"
                            loading="lazy"
                            className={styles.logo}
                            style={{ opacity: webSettings?.globals.logo ? 1 : 0 }}
                            onClick={() => navigate("/manage-playlists/profile")}
                        />

                        <NavLink
                            to="/manage-playlists/profile"
                            className={({ isActive }) =>
                                classNames(
                                    styles["menu-list__item__link"],
                                    { [styles["active-link"]]: isActive }
                                )
                            }
                        >
                            {handleText('my_profile')}
                        </NavLink>

                        <NavLink
                            to="/manage-playlists/playlists"
                            className={({ isActive }) =>
                                classNames(
                                    styles["menu-list__item__link"],
                                    { [styles["active-link"]]: isActive }
                                )
                            }
                        >
                            {handleText("my_playlist")}
                        </NavLink>
                    </div>
                    <Button onClick={logout} className={styles["btn"]}>
                        {handleText('log_out')}
                    </Button>

                </Header>
                <Content
                    style={{
                        padding: 20
                    }}
                >
                    <div className={styles['container']}>
                        <Outlet />
                    </div>
                </Content>
            </Layout>
            {contextHolder}
        </Layout >
    )
}
