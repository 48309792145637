import React, { useEffect } from "react";
import { useRef } from "react";

export default function Messages({ messages }) {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="messages" ref={ref}>
      {messages?.map((message) => {
        return (
          <div>
            <div
              className={
                message.sender === "admin" ? "message admin" : "message user"
              }
            >
              {message.message}
            </div>
          </div>
        );
      })}
    </div>
  );
}
