import { useState, useEffect } from "react";
import { Upload, Modal } from "antd";
import { LoadingOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";

import REQUESTS from "../../api/requests";

import style from "../style/image-component.module.scss";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export default function ImageComponent({
    defaultImage,
    updateImage,
    deleteImage,
    title,
    disabled,
    size,
    onChangeSize,
}) {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");

    const uploadImageHandler = (val) => {
        const file = val.file;

        REQUESTS.IMAGE_UPLOAD(
            file,
            function (data) {
                setImageUrl(data);
                setLoading(false);
                updateImage(data, true);
                val.onSuccess();
            },

            function (err) {
                setLoading(false);
            }
        );
    };

    const changeImmidiate = (val) => {
        const file = val.file;

        if (file.uid === "-1") return;

        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => {
            setLoading(false);
        };

        reader.onerror = (error) => {
            setLoading(false);
        };
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
    };

    const removeImage = (e) => {
        e.stopPropagation();
        deleteImage();
        setImageUrl(null);
    };

    useEffect(() => {
        if (defaultImage) {
            setImageUrl(defaultImage);
        }
    }, [defaultImage]);

    return (
        <div className={style["image-component"]}>
            <div className={style["header-block"]}>
                {title && <h4>{title}</h4>}
                {size && (
                    <div className={style["size"]}>
                        <input
                            min={20}
                            value={size}
                            type="number"
                            onChange={(e) => onChangeSize(e.target.value)}
                        />{" "}
                        <span>px</span>
                    </div>
                )}
            </div>

            <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                onPreview={handlePreview}
                maxCount={1}
                onChange={changeImmidiate}
                customRequest={uploadImageHandler}
                disabled={disabled}
            >
                {imageUrl ? (
                    <div className={style["image-block"]}>
                        <img
                            src={imageUrl}
                            alt="avatar"
                            style={{
                                width: "100%",
                            }}
                        />

                        {!disabled && (
                            <div className={style["remove-button"]} onClick={removeImage}>
                                <DeleteOutlined />
                            </div>
                        )}
                    </div>
                ) : (
                    uploadButton
                )}
            </Upload>

            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: "100%",
                    }}
                    src={previewImage}
                />
            </Modal>
        </div>
    );
}
