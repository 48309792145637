import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import REQUESTS from '../../../api/requests';
import Wrapper from '../../../components/Wrapper';
import useTranslation from '../../../hooks/useTranslation';

import TEXT from '../../../config/text';
import { ICONS } from "../../../config/icons";

import styles from "../styles/devices.module.scss";
import AddDevice from './AddDevice';
import Device from './Device';
import MultipleDeviceLoginEditor from '../../../editors/MultipleDeviceLoginEditor';

export default function Devices() {
  const [devices, setDevices] = useState([]);

  const [open, setOpen] = useState(false);

  const translation = useTranslation();

  const { webSettings, isAdmin } = useSelector((state) => state.globalState);

  const pageStyle = {
    backgroundColor: webSettings?.pages?.multiple_device_login?.background_color,
    backgroundImage: `url(${webSettings?.pages?.multiple_device_login?.background_image})`,
    backgroundSize: "cover",
  };

  const handleText = (text) => {
    if (translation?.pages?.multiple_device_login?.[text]) {
      return translation?.pages?.multiple_device_login?.[text];
    }
    return TEXT?.pages?.multiple_device_login?.[text];
  };

  const getDevices = () => {
    function callback(data) {
      setDevices(data.rows || [])
    };

    function errorCallback() { };

    REQUESTS.DEVICES(callback, errorCallback)
  };

  useEffect(() => {
    getDevices()
  }, [])

  return (
    <div style={pageStyle} className="page-wrapper">
      {isAdmin && <MultipleDeviceLoginEditor />}
      <div style={{width:'90%'}}>
         <Wrapper>
          <div className={styles["devices-page-top"]}>
            <h1 className={styles["title"]}>
              {handleText("devices")}
            </h1>
            <div
              className={styles["add-btn"]}
              onClick={() => setOpen(true)}
            >
              {ICONS.ADD}
              <span> {handleText("add_device")}</span>
            </div>
          </div>

          {devices.map(device => <Device device={device} getDevices={getDevices} />)}

          <AddDevice open={open} setOpen={setOpen} getDevices={getDevices} />
      </Wrapper>
      </div>
    </div>
  )
}
