import { bindActionCreators } from "redux";

import { useDispatch, useSelector } from "react-redux";

import { actions } from "../store";

import EditorDrawer from "./components/EditorDrawer";
import ImageComponent from "./components/ImageComponent";
import ColorComponent from "./components/ColorComponent";

export default function ActivationEditor({}) {
    const dispatch = useDispatch();

    const { setWebSettings } = bindActionCreators(actions, dispatch);

    const { webSettings } = useSelector((store) => store.globalState);

    const onChangeBackgroundColor = (color) => {
        let configs = { ...webSettings };

        configs.pages.activation.background_color = color;

        setWebSettings(configs);
    };

    const onChangeBackgroundImage = (image) => {
        let configs = { ...webSettings };

        configs.pages.activation.background_image = image;

        setWebSettings(configs);
    };

    const { background_color, background_image } = webSettings?.pages?.activation;

    return (
        <div>
            <EditorDrawer title={"Activation settings"}>
                <ImageComponent
                    title={"Background image"}
                    defaultImage={background_image}
                    updateImage={(e) => onChangeBackgroundImage(e)}
                    deleteImage={() => onChangeBackgroundImage("")}
                />

                <ColorComponent
                    text="Background color"
                    defaultColor={background_color}
                    callback={onChangeBackgroundColor}
                />
            </EditorDrawer>
        </div>
    );
}
