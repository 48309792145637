import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/autoplay";

import IMAGES from "../../../config/images";

SwiperCore.use([Autoplay]);

export default function InfiniteAutoplaySlider() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [countRenderedPlatform, setCountRederedPlatform] = useState(3);

  const { webSettings } = useSelector((state) => state.globalState);

  const sectionStyle = {
    backgroundColor:
      webSettings &&
      webSettings.pages &&
      webSettings.pages.home &&
      webSettings.pages.home.platforms &&
      webSettings.pages.home.platforms.background_color,
  };

  const list = [
    {
      id: 1,
      icon:
        (webSettings &&
          webSettings.pages &&
          webSettings.pages.home &&
          webSettings.pages.home.platforms &&
          webSettings.pages.home.platforms.apple_tv &&
          webSettings.pages.home.platforms.apple_tv.icon) ||
        IMAGES.APPLE,
    },
    {
      id: 2,
      icon:
        (webSettings &&
          webSettings.pages &&
          webSettings.pages.home &&
          webSettings.pages.home.platforms &&
          webSettings.pages.home.platforms.android &&
          webSettings.pages.home.platforms.android.icon) ||
        IMAGES.ANDROID,
    },
    {
      id: 3,
      icon:
        (webSettings &&
          webSettings.pages &&
          webSettings.pages.home &&
          webSettings.pages.home.platforms &&
          webSettings.pages.home.platforms.lg &&
          webSettings.pages.home.platforms.lg.icon) ||
        IMAGES.LG,
    },
    {
      id: 4,
      icon:
        (webSettings &&
          webSettings.pages &&
          webSettings.pages.home &&
          webSettings.pages.home.platforms &&
          webSettings.pages.home.platforms.samsung &&
          webSettings.pages.home.platforms.samsung.icon) ||
        IMAGES.SAMSUNG,
    },
    {
      id: 5,
      icon:
        (webSettings &&
          webSettings.pages &&
          webSettings.pages.home &&
          webSettings.pages.home.platforms &&
          webSettings.pages.home.platforms.hisense &&
          webSettings.pages.home.platforms.hisense.icon) ||
        IMAGES.HISENSE,
    },
    {
      id: 6,
      icon:
        (webSettings &&
          webSettings.pages &&
          webSettings.pages.home &&
          webSettings.pages.home.platforms &&
          webSettings.pages.home.platforms.vidaa &&
          webSettings.pages.home.platforms.vidaa.icon) ||
        IMAGES.VIDAA,
    },
    {
      id: 7,
      icon:
        (webSettings &&
          webSettings.pages &&
          webSettings.pages.home &&
          webSettings.pages.home.platforms &&
          webSettings.pages.home.platforms.philips &&
          webSettings.pages.home.platforms.philips.icon) ||
        "",
    },
    {
      id: 8,
      icon:
        (webSettings &&
          webSettings.pages &&
          webSettings.pages.home &&
          webSettings.pages.home.platforms &&
          webSettings.pages.home.platforms.xiaomi &&
          webSettings.pages.home.platforms.xiaomi.icon) ||
        "",
    },
    {
      id: 9,
      icon:
        (webSettings &&
          webSettings.pages &&
          webSettings.pages.home &&
          webSettings.pages.home.platforms &&
          webSettings.pages.home.platforms.vizio &&
          webSettings.pages.home.platforms.vizio.icon) ||
        "",
    },
    {
      id: 10,
      icon:
        (webSettings &&
          webSettings.pages &&
          webSettings.pages.home &&
          webSettings.pages.home.platforms &&
          webSettings.pages.home.platforms.fire_tv &&
          webSettings.pages.home.platforms.fire_tv.icon) ||
        "",
    },
  ];

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);

      if (window.innerWidth >= 1200) {
        setCountRederedPlatform(3);
      } else if (window.innerWidth >= 700) {
        setCountRederedPlatform(2);
      } else {
        setCountRederedPlatform(1);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {}, [windowWidth]);

  return (
    <>
      <div style={sectionStyle}>
        <Swiper
          autoplay={{ delay: 0 }}
          spaceBetween={10}
          slidesPerView={countRenderedPlatform}
          loop={true}
          speed={3000}
          style={{
            width: windowWidth > 1200 ? 1200 : windowWidth - 60,
            padding: "70px 0 30px",
          }}
        >
          {list.map((item) => {
            return (
              <SwiperSlide key={item.id}>
                <img src={item.icon} alt="" />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}
