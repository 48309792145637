import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Checkbox, Form, Select } from "antd";
import PropTypes from "prop-types";

import ReCAPTCHA from "react-google-recaptcha";

import useTranslation from "../../hooks/useTranslation";

import country from "../../assets/country.json";

import TEXT from "../../config/text";
import PhoneInput from "./PhoneInput";

import BecomePartner from "./BecomePartner";

import InputComponent from "../../components/InputComponent";
import ButtonComponent from "../../components/ButtonComponent";
import PATHS from "../../config/paths";
import REQUESTS from "../../api/requests";

/**
 * The second step in the reseller registration procedure is stated here and includes selecting a nation, providing address, phone number,
 * and partner option (Reseller or Referral, or both), as well as validating privacy policy and terms of usage.
 *
 * @param {object} form                 This is a state of our reseller form.
 * @param {bool} loading                For loading the register button display

 *
 * @return Second step
 */

export default function Step2({
  form,
  loading,

  agreeWithAll,
  setAgreeWithAll,
}) {
  const translation = useTranslation();

  const [countries, setCountries] = useState([]);
  const [phoneValue, setPhoneValue] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [captchaSiteKey, setCaptchaSieKey] = useState(null);

  const getCaptchaSiteKey = () => {
    REQUESTS.CAPTCHA.GET_SITE_KEY(
      (data) => {
        setCaptchaSieKey(data);
      },
      (err) => { setCaptchaSieKey(null); }
    );
  };

  const handleText = (text) => {
    if (translation?.pages?.register?.[text]) {
      return translation?.pages?.register?.[text];
    }

    return TEXT?.pages?.register?.[text];
  };

  const onChangePhone = (e) => {
    form.setFields([{ name: "phone", value: e }]);
    setPhoneValue(e);
  };

  function onChange(value) {
    REQUESTS.CAPTCHA.SENT_CAPTCHA_TOKEN(
      { token: value },
      (message) => {
        setIsValid(message.success);
      },
      (err) => setIsValid(false)
    );
  }

  useEffect(() => {
    const _countries = country.map((item) => ({
      label: item.name,
      value: item.name,
    }));

    setCountries(_countries);
  }, [country]);

  useEffect(() => {

    getCaptchaSiteKey();
  }, []);


  return (
    <div>
      <BecomePartner />

      <Form.Item
        name="country"
        rules={[
          {
            required: true,
            message: handleText("country_select_message"),
          },
        ]}
      >
        <Select
          showSearch={true}
          options={countries}
          placeholder={handleText("country_select_placeholder")}
        />
      </Form.Item>

      <Form.Item
        name="address"
        rules={[
          {
            required: true,
            message: handleText("address_input_message"),
          },
        ]}
      >
        <InputComponent placeholder={handleText("address_input_placeholder")} />
      </Form.Item>

      <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: handleText("phone_input_message"),
          },
        ]}
      >
        <PhoneInput
          name="phone"
          value={phoneValue}
          handleChange={onChangePhone}
        />
      </Form.Item>

      <p className="reseller-registr-page__text">{handleText("choose_partner_type")}</p>

      <Form.Item name="is_reseller" valuePropName="checked" noStyle>
        <Checkbox
          className="checkbox"
          style={{ width: "100%", marginBottom: 10 }}
        >
          {handleText("reseller")}
        </Checkbox>
      </Form.Item>

      <Form.Item name="is_referral" valuePropName="checked" noStyle>
        <Checkbox
          className="checkbox"
          style={{ width: "100%", margin: 0, marginBottom: 10 }}
        >
          {handleText("referral")}
        </Checkbox>
      </Form.Item>

      <p className="reseller-registr-page__text">{handleText("accept_text")}</p>

      <Checkbox
        checked={agreeWithAll}
        className="reseller-page-agree-with-all"
        onChange={(e) => setAgreeWithAll(e.target.checked)}
      >
        <Link to={PATHS.PRIVACY} target="_blank">
          {handleText("agree_text")}
        </Link>
      </Checkbox>
      {captchaSiteKey && (
        <ReCAPTCHA sitekey={captchaSiteKey} onChange={onChange} />
      )}
      <div style={{ marginTop: "40px", textAlign: "center" }}>
        <ButtonComponent
          loading={loading}
          onClick={() => form.submit()}
          disabled={captchaSiteKey
            ? (!agreeWithAll || !isValid)
            :
            !agreeWithAll}
          title={handleText("step_two_button_title")}
        />
      </div>
    </div>
  );
}

Step2.propTypes = {
  form: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};
