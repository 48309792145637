import { bindActionCreators } from "redux";

import { useDispatch, useSelector } from "react-redux";

import { actions } from "../store";

import EditorDrawer from "./components/EditorDrawer";
import ColorComponent from "./components/ColorComponent";
import ImageComponent from "./components/ImageComponent";

export default function MultipleDeviceLoginEditor() {
    const dispatch = useDispatch();

    const { setWebSettings } = bindActionCreators(actions, dispatch);

    const { webSettings } = useSelector((store) => store.globalState);

    const onChangeBackgroundColor = (color) => {
        let configs = { ...webSettings };

        if(configs.pages.multiple_device_login){

            configs.pages.multiple_device_login.background_color = color;
            
        }else{

            configs.pages.multiple_device_login={
                background_color:color,
                background_image:""
            }

        }

        setWebSettings(configs);
    };

    const onChangeBackgroundImage = (image) => {
        let configs = { ...webSettings };

        if(configs.pages.multiple_device_login){

            configs.pages.multiple_device_login.background_image = image;

        }else{

            configs.pages.multiple_device_login={
                background_color:'',
                background_image:image,
            }

        }

        setWebSettings(configs);
    };
  return (
    <EditorDrawer title={"Multiple Device Login"}>
                <ImageComponent
                    title={"Background image"}
                    defaultImage={webSettings?.pages?.multiple_device_login?.background_image}
                    updateImage={(e) => onChangeBackgroundImage(e)}
                    deleteImage={() => onChangeBackgroundImage("")}
                />

                <ColorComponent
                    text="Background color"
                    defaultColor={webSettings?.pages?.multiple_device_login?.background_color}
                    callback={onChangeBackgroundColor}
                />
            </EditorDrawer>
  )
}
