import PropTypes from "prop-types";

export default function PageComponent({ children }) {
    return (
        <div
        className="page-component"
            style={{
                width: "100%",
                minHeight: "calc(100vh - 80px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 80,
            }}
        >
            {children}
        </div>
    );
}

PageComponent.propTypes = {
    children: PropTypes.node.isRequired,
};
