import React from 'react'
import { Input } from "antd";

export default function InputComponent({ value, onChange, disabled ,placeholder=""}) {
    return (
        <div style={{ margin: "10px 0" }}>
            <Input
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                disabled={disabled} />
        </div>
    )
}
