import PropTypes from "prop-types";

import style from "./style/tabBar.module.scss";

/**
 *
 * @param {array} tabs          The tab's items must be numbers depends the count
 * @param {string} activeKey    To view the currently rendered tab
 * @param {func} onChange       Alters the asset, but only to back
 * @return Component
 */

export default function TabBar({ tabs = [], activeKey, onChange }) {
    const onChangeTab = (item) => {
        if (activeKey <= item) {
            return;
        }

        onChange(item);
    };

    return (
        <div className={style["tab-bar"]}>
            {tabs.map((item) => {
                return (
                    <div
                        onClick={() => onChangeTab(item.toString())}
                        key={item}
                        className={`${style["item"]} ${activeKey == item && style["active"]} ${
                            activeKey < item.toString() && style["dont-hover"]
                        }`}
                    >
                        {item}
                    </div>
                );
            })}
        </div>
    );
}

TabBar.propTypes = {
    tabs: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    activeKey: PropTypes.string.isRequired,
};
