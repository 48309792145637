import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { HOST } from "../../../config";
import styles from "../style/stripePaymentForm.module.scss";

export default function StripePaymentForm () {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${ HOST }payment/stripe/success`,
      },
    });

    if (result.error) {
      navigate("/payment-error");
    }
  };

  return (
    <div>
      {loading && (
        <div className={styles.loading}>
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 30,
                }}
                spin
              />
            }
          />
        </div>
      )}
      <form onSubmit={handleSubmit} className={styles["stripe-payment-form"]}>
        <PaymentElement onReady={() => setLoading(false)} />
        {!loading && (
          <button disabled={!stripe} className={styles["stripe-submit-button"]}>
            Submit
          </button>
        )}
      </form>
    </div>
  );
}
