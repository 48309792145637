import web_settings from "../../config/web_settings";

const initialState = {
    appInfo: {},
    translation: {},
    isAdmin: false,
    widgets: {},
    webSettings: web_settings,
    selectedLanguage: "en",
};

const globalReducer = (state = initialState, action) => {
    switch (action.type) {
        case "appInfo":
            return { ...state, appInfo: action.payload };

        case "translation":
            return { ...state, translation: action.payload };

        case "isAdmin":
            return { ...state, isAdmin: action.payload };

        case "widgets":
            return { ...state, widgets: action.payload };

        case "webSettings":
            return { ...state, webSettings: action.payload };

        case "selectedLanguage":
            return { ...state, selectedLanguage: action.payload };

        default:
            return state;
    }
};

export default globalReducer;
