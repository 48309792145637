import PropTypes from "prop-types";
import "./_style.scss";

/**
 * The component is designed to present the package. The details are as follows.
 *
 * @param {string} name            Package name
 * @param {number} price           Package price
 * @param {func} onClick           It is selection function
 * @param {bool} active            It is the chosen one by user
 * @param {bool} recomended        One of the tariffs assigned by us recommended
 * @param {string} time            It is composed package's schedule and time (schedule_time)
 * @param {string} currency        Provider currency is changed from the admin panel, we take it from the app info
 *
 * @return Package component
 */

export default function Package({ name, time, price, active, onClick, currency, type }) {
    return (
        <>
            {type === time && (
                <div className={`package ${active && "active"}`} onClick={onClick}>
                    <div className="name">{name}</div>
                    <span className="schedule-time">
                        {(() => {
                            switch (time) {
                                case "year":
                                    return "Yearly";
                                    break;
                                case "month":
                                    return "Mothly";
                                    break;
                                case "lifetime":
                                    return "Lifetime";
                                    break;

                                default:
                                    break;
                            }
                        })()}
                    </span>
                    <div className="price">
                        <div style={{ marginBottom: 4 }}> {currency}</div>
                        <div style={{ fontSize: 28 }}>{price}</div>
                    </div>
                </div>
            )}
        </>
    );
}

Package.propTypes = {
    time: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    currency: PropTypes.string.isRequired,
    recomended: PropTypes.bool.isRequired,
};
