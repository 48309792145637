import classes from "./style/message.module.scss";
import classNames from "classnames";
// The Message component is used to display a message to the user.
// Massage can be either a success or error message.

export default function Message({ message, style }) {
  return (
    message && (
      <div className={classes[message.text ? "message-open" : "message-close"]}>
        <span
          className={classNames({
            [classes["success-text"]]: message.type,
            [classes["error-text"]]: !message.type,
          })}
        >
          {message.text}
        </span>
      </div>
    )
  );
}
